window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

document.addEventListener('DOMContentLoaded', function() {
  // Create banner elements
  const banner = document.createElement('div');
  banner.style.cssText = `
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #EEF2FF;
    padding: 16px;
    text-align: center;
    z-index: 1000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    border-top: 1px solid #E5E7EB;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  `;
  const text = document.createElement('span');
  text.style.cssText = 'color: #374151;';
  text.textContent = "30% off if your order in the next ";
  const timer = document.createElement('span');
  timer.style.cssText = `
    background-color: #2563EB;
    color: white;
    padding: 6px 16px;
    border-radius: 20px;
    font-weight: 500;
    min-width: 80px;
    display: inline-block;
  `;
  timer.textContent = '10:00';
  // Append elements
  banner.appendChild(text);
  banner.appendChild(timer);
  document.body.insertBefore(banner, document.body.firstChild);
  // Timer functionality
  let totalSeconds = 600; // 10 minutes
  const countdown = setInterval(() => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    timer.textContent = 
      (minutes < 10 ? '0' : '') + minutes + ':' + 
      (seconds < 10 ? '0' : '') + seconds;
    
    if (totalSeconds <= 0) {
      clearInterval(countdown);
    } else {
      totalSeconds--;
    }
  }, 1000);
});